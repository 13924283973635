var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Form, Input, Modal, Select, showErrorMessage, Spinner } from "@g4p/react-styleguide-library";
import { BANK_SIGNATURE_SYSTEM_NOT_RELEVANT_VALUE, CLIENT_SIGNATURE_SYSTEM_NOT_RELEVANT_VALUE, } from "coredde/lib/flows/BankerCreateDokuBox/services/createDokuBoxApi";
import { getByLocale } from "coredde/lib/services/appApi";
import { executeAxios } from "coredde/lib/services/axiosHook";
import { useFormatMessage } from "coredde/lib/services/formatMessageHook";
import { useEffect, useState } from "react";
var getDokuboxdefinitionListApiConfig = {
    url: "/dde/dokubox/dokudefinition/filter",
    method: "POST",
};
var ClientAddingOtherDokuRO = function (props) {
    var isVisible = props.isVisible, setIsVisible = props.setIsVisible;
    var formatMessage = useFormatMessage().formatMessage;
    var addOtherDokuForm = Form.useForm()[0];
    var _a = useState([]), dokuDefinitionItems = _a[0], setDokuDefinitionItems = _a[1];
    var _b = useState(), selectedDokuDefinition = _b[0], setSelectedDokuDefinition = _b[1];
    var _c = useState(false), loading = _c[0], setLoading = _c[1];
    var digitalSignatureTypeNone = "NONE";
    var requiredRule = { required: true, message: formatMessage("dde.validation.common.requiredField") };
    useEffect(function () {
        var apiConfig = {
            data: {
                permitted: true,
                initiatedBy: "CLIENT",
            },
        };
        executeAxios({
            params: __assign(__assign({}, getDokuboxdefinitionListApiConfig), apiConfig),
            onSuccess: function (response) {
                var dokuDefinitionResponse = response || [];
                var dokuDefinitions = dokuDefinitionResponse.map(function (dokuDefinition) {
                    return __assign(__assign({}, dokuDefinition), { value: dokuDefinition.id, label: getByLocale(dokuDefinition.titleEn, dokuDefinition.titleLocal) });
                });
                setDokuDefinitionItems(dokuDefinitions);
            },
            onFail: function (resp) {
                var _a;
                return showErrorMessage({
                    title: formatMessage("dde.error.errorTitle"),
                    message: formatMessage(((_a = resp === null || resp === void 0 ? void 0 : resp.data) === null || _a === void 0 ? void 0 : _a.messageKey) || "dde.error.generalError"),
                });
            },
            onLoading: setLoading,
        });
    }, [formatMessage]);
    var handleSubmit = function () {
        if (selectedDokuDefinition) {
            addOtherDokuForm.validateFields().then(function (values) {
                var dokuDto = values;
                dokuDto.documentName = getByLocale(selectedDokuDefinition.titleEn, selectedDokuDefinition.titleLocal);
                props.addOtherDoku(dokuDto);
                setIsVisible(false);
            });
        }
    };
    var handleCancel = function () {
        setIsVisible(false);
        addOtherDokuForm.resetFields();
    };
    var isClientSignatureNeeded = function (dokuDefinitionId) {
        var dokuDefinition = dokuDefinitionItems.find(function (definition) { return definition.id && definition.id === dokuDefinitionId; });
        if (dokuDefinition) {
            return ((dokuDefinition.clientLegalRepSignatureNeeded === true && dokuDefinition.clientLegalRepSignatureType !== digitalSignatureTypeNone) ||
                (dokuDefinition.clientNonLegalRepSignatureNeeded === true && dokuDefinition.clientNonLegalRepSignatureType !== digitalSignatureTypeNone));
        }
    };
    var isBankSignatureNeeded = function (dokuDefinitionId) {
        var dokuDefinition = dokuDefinitionItems.find(function (definition) { return definition.id && definition.id === dokuDefinitionId; });
        if (dokuDefinition) {
            return ((dokuDefinition.firstBankLegalRepSignatureNeeded === true && dokuDefinition.firstBankLegalRepSignatureType !== digitalSignatureTypeNone) ||
                (dokuDefinition.secondBankLegalRepSignatureNeeded === true && dokuDefinition.secondBankLegalRepSignatureType !== digitalSignatureTypeNone) ||
                (dokuDefinition.firstBankNonLegalRepSignatureNeeded === true &&
                    dokuDefinition.firstBankNonLegalRepSignatureType !== digitalSignatureTypeNone) ||
                (dokuDefinition.secondBankNonLegalRepSignatureNeeded === true &&
                    dokuDefinition.secondBankNonLegalRepSignatureType !== digitalSignatureTypeNone));
        }
    };
    var validateSignatureNeeded = function (rule, value, callback) {
        if (props.setupComplete && props.clientSignatureSystem === CLIENT_SIGNATURE_SYSTEM_NOT_RELEVANT_VALUE && isClientSignatureNeeded(value === null || value === void 0 ? void 0 : value.value)) {
            callback(formatMessage("dde.otherDoku.error.selectedDokuNeedsClientSignature"));
        }
        else if (props.setupComplete && props.bankSignatureSystem === BANK_SIGNATURE_SYSTEM_NOT_RELEVANT_VALUE && isBankSignatureNeeded(value === null || value === void 0 ? void 0 : value.value)) {
            callback(formatMessage("dde.otherDoku.error.selectedDokuNeedsBankSignature"));
        }
        else {
            callback(undefined);
        }
    };
    return (_jsx(_Fragment, { children: _jsxs(Modal, __assign({ title: formatMessage("dde.dde.clientManageDokuBox.addOtherDoku.title"), open: isVisible, onCancel: handleCancel, onOk: handleSubmit, okText: formatMessage("dde.dde.clientManageDokuBox.addOtherDoku.addBtn"), cancelText: formatMessage("dde.dde.clientManageDokuBox.addOtherDoku.cancelBtn"), afterClose: function () {
                setSelectedDokuDefinition(undefined);
                addOtherDokuForm.resetFields();
            } }, { children: [_jsx("div", __assign({ style: { padding: "10px" } }, { children: _jsxs(Form, __assign({ form: addOtherDokuForm, initialValues: {} }, { children: [_jsx("label", __assign({ className: "input-label", htmlFor: "dokuDefinition" }, { children: _jsx("div", { children: formatMessage("dde.dde.bankerManageDokuBox.addOtherDoku.dokuDefinition") }) })), _jsx(Form.Item, __assign({ rules: [requiredRule, { validator: validateSignatureNeeded }], name: "dokuDefinitionSelect" }, { children: _jsx(Select, { placeholder: formatMessage("dde.dde.bankerManageDokuBox.addOtherDoku.selectPlaceHolder"), onChange: function (option) {
                                        var dokuDefinition = dokuDefinitionItems.find(function (item) { return item.id && item.id === (option === null || option === void 0 ? void 0 : option.value); });
                                        if (option === undefined || !(dokuDefinition === null || dokuDefinition === void 0 ? void 0 : dokuDefinition.dokuFlowTypeId)) {
                                            addOtherDokuForm.setFieldsValue({
                                                dokuDefinition: undefined,
                                                dokuDescription: undefined,
                                            });
                                            setSelectedDokuDefinition(undefined);
                                        }
                                        else {
                                            addOtherDokuForm.setFieldsValue({
                                                dokuDefinition: dokuDefinition.id,
                                                dokuDescription: getByLocale(dokuDefinition.instructionEn, dokuDefinition.instructionLocal),
                                            });
                                            setSelectedDokuDefinition(dokuDefinition);
                                        }
                                    }, options: dokuDefinitionItems && dokuDefinitionItems.length > 0 ? dokuDefinitionItems : undefined, isClearable: true, isSearchable: true }) })), _jsx(Form.Item, { name: "dokuDefinition" }), _jsx("label", __assign({ className: "input-label", htmlFor: "dokuDescription" }, { children: formatMessage("dde.dde.bankerManageDokuBox.addOtherDoku.dokuDescription") })), _jsx(Form.Item, __assign({ name: "dokuDescription" }, { children: _jsx(Input.TextArea, { style: { width: "60%" }, rows: 3, id: "dokuDescription", disabled: true, placeholder: formatMessage("dde.dde.bankerManageDokuBox.addOtherDoku.dokuDescription") }) })), _jsx("br", {})] })) })), loading && _jsx(Spinner, {})] })) }));
};
export default ClientAddingOtherDokuRO;
